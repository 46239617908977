.bar{

  .link--main{
    display: inline-block;
    white-space: nowrap;
    color: @white;
    font-size: 2rem;
    font-weight: 700;
    transform-origin: top left;
    .rotate( 270deg );
    position: absolute;
    bottom: 0;
    left: 0;
    height: @barDesktopWidth;
    line-height: @barDesktopWidth;
    .interactionTransition();

    @media @deskS{
      height: @barTableWidth;
      line-height: @barTableWidth;
      font-size: 1.66rem;
    }

    @media @tabL{
      font-size: 1.5rem;
    }

    @media @tabP {
      height: @barMobileWidth;
      line-height: @barMobileWidth;
    }

    @media @smL{
      font-size: 1.25rem;
    }

    @media @smX{
      font-size: 1rem;
    }

    &:hover,
    &:active{
      color: @yellow;
    }
  }




  .link--top{
    display: inline-block;
    width: 250px;
    padding-left: 3rem;
    white-space: nowrap;
    font-size: 1.75rem;
    font-weight: 500;
    transform-origin: top left;
    .rotate( 270deg );
    position: absolute;
    top: 250px;
    left: 0;
    height: @barDesktopWidth;
    line-height: @barDesktopWidth;
    .interactionTransition();

    @media @deskS{
      height: @barTableWidth;
      line-height: @barTableWidth;
    }

    @media @tabL{
      width: 220px;
      top: 200px;
      font-size: 1.5rem;
      padding-left: 2rem;
    }

    @media @tabP{
      height: @barMobileWidth;
      line-height: @barMobileWidth;
    }

    @media @smL{
      width: 180px;
      top: 180px;
      font-size: 1.25rem;
    }

    &.link--blue{
      background: @blue;
      color: @white;

      &:hover,
      &:active{
        color: @yellow;
      }
    }

  }
}











.lightbar{
  position: absolute;
  top: 0;
  bottom: 0;
  width: @lightbarWidth;
  background: @lightBarBg;
  display: flex;
  flex-wrap: wrap;

  .bar--logo{
    width: 100%;
    height: auto;
  }

  .navigation{
    align-self: end;
    margin-bottom: 12rem;
    width: 100%;
  }

  &.lightbar--left{
    left: @barDesktopWidth;

    @media @deskS{
      width: @lightbarTableWidth;
      left: @barTableWidth;
    }
  }

  &.lightbar--right{
    right: @barDesktopWidth;

    @media @deskS{
      width: @lightbarTableWidth;
      right: @barTableWidth;
    }
  }

  @media @tabL{
    display: none;
  }




  .bar--logo{
    height: 250px;
    background: @white;
    width: 100%;
  }
}