.rotate(@deg){
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  transform: rotate(@deg);
}


.interactionTransition( @element: all, @dur: 0.33s, @delay: 0s ){
  -webkit-transition: @element @dur ease-in-out @delay;
  -moz-transition: @element @dur ease-in-out @delay;
  -ms-transition: @element @dur ease-in-out @delay;
  transition: @element @dur ease-in-out @delay;
}