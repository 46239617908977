.main--content{
  max-width: 800px;
  width: 100%;
  margin: auto;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 132px 0;

  @media @tabP{
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
  }

  p{
    margin-bottom: 1rem;
  }

  h1{
    font-weight: 400;
    font-size: 2rem;
    color: @darkGrey;
    margin-bottom: 2rem;
  }

  ul{
    margin: 1rem 0;

    li{
      margin-bottom: 0.5rem;
      padding-left: 1.5rem;
      position: relative;

      &::before{
        width: 6px;
        height: 6px;
        display: block;
        position: absolute;
        top: 0.66rem;
        left: 0;
        content: "";
        background: @blue;
      }
    }
  }

  h2{
    color: @blue;
    font-size: 1.75rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  h3{
    font-size: 1.5rem;
    color: @darkGrey;
    font-weight: 500;
    margin-bottom: 1.33rem;
  }

  p + h3{
    margin-top: 2rem;
  }

  p + h2{
    margin-top: 2rem;
  }

  a{
    color: @blue;
    .interactionTransition();

    &:hover,
    &:active{
      color: @yellow;
    }
  }
}