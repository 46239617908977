.wrapper{
  max-width: 100vw;
  height: 100vh;
  position: relative;
}


.middle{
  position: absolute;
  top: 0;
  left: @barDesktopWidth;
  right: @barDesktopWidth;
  bottom: @footerHeight;
  background: url('../img/kaufhold-bau-gmbh.jpg') no-repeat center center;
  background-size: cover;

  @media @deskS{
    left: @barTableWidth;
    right: @barTableWidth;
  }

  @media @tabP{
    left: 0;
    right: 0;
  }

  @media @smL{
    background-position: 65% center;
  }
}




.bar{
  width: @barDesktopWidth;
  background: @darkGrey;
  height: 100vh;
  position: absolute;
  top: 0;

  @media @deskS{
    width: @barTableWidth;
  }

  @media @tabP{
    width: @barMobileWidth;
  }

  @media @smL{
    height: auto;
    bottom: 50px;
  }

  &.bar--left{
    left: 0;
  }

  &.bar--right{
    right: 0;
    left: auto;
  }
}


.footer{
  height: @footerHeight;
  background: @yellow;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}