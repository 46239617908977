.menu{
  padding: 1rem 1rem 0 2rem;

  li:not(:first-child){
    margin-top: 1rem;
  }

  a{
    color: @white;
    display: block;
    .interactionTransition();

    &:hover,
    &:active{
      color: @yellow;
    }
  }
}


.lightbar--right{

  .menu{
    padding: 1rem 2rem 0 1rem;
    text-align:right;
  }
}