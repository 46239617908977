


@blue:    #2744a0;
@yellow:  #fab900;
@white:   #fff;
@darkGrey:  #111111;
@textColor: #5d5d5d;
@lightBarBg:  rgba(0,0,0,0.6);


@stdFontsize: 16px; // root em ausgangswert
@stdLineHeight: 1.5;
@stdFont: "Rubik", Calibri, Helvetica, sans-serif;



@barDesktopWidth: 200px;
@barTableWidth:   150px;
@barMobileWidth:  20vw;

@lightbarWidth: 240px;
@lightbarTableWidth: 200px;

@footerHeight:        100px;
@mobileFooterHeight:  100px;



@deskL: ~" screen and (min-width: 1441px)";
@deskS: ~" screen and (max-width: 1440px)";
@desk:  ~" screen and (min-width: 1142px)";
@tabL:  ~" screen and (max-width: 1141px)";
@tabP:  ~" screen and (max-width: 853px)";
@smL:   ~" screen and (max-width: 620px)";
@smP:   ~" screen and (max-width: 415px)";
@smX:   ~" screen and (max-width: 375px)";