.header--bar{
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: @white;
  box-shadow: 0 2px 3px rgba(0,0,0,0.4);

  @media @tabP {
    height: 66px;
  }
}

.header--bar-left{
  flex: auto;
  background: @blue;

  a{
    height: 100%;
    color: @yellow;
    text-align: center;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media @smL{
    width: 33%;

    a{
      font-size: 1.125rem;
    }
  }
}
.header--bar-right{
  flex: auto;
  background: @blue;

  a{
    height: 100%;
    color: @yellow;
    text-align: center;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media @smL{
    width: 33%;

    a{
      font-size: 1.125rem;
    }
  }
}

.header--bar-center{
  max-width: 800px;
  width: 100%;

  a{
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;

    img{
      height: 100%;
      width: auto;
      display: block;
      margin: auto;
    }
  }

  @media @smL{
    width: 34%;
  }
}