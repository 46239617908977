.footer{

  display: flex;
  @media @smL{
    display: none;
  }
}
.footer--left,
.footer--right{
  width: @barDesktopWidth + @lightbarWidth;
  box-sizing: border-box;

  p{
    margin: 0;
    padding-top: 1rem;
    font-weight: 500;

    &.telnr{
      padding-top: 0;
      font-size: 2rem;
      color: @white;
      line-height: 1;
    }
  }
}
.footer--left{
  padding-left: 5rem;
}
.footer--right{
  padding-left: 5rem;
}
.footer--center{
  flex: auto;
  text-align: center;
  padding-top: 2rem;

  a{
    margin-right: 2rem;
    color: @darkGrey;

    & + a{
      margin-right: 0;
    }
  }
}



.footer--phone{
  display: none;
}


@media @smL {

  .footer--phone {
    display: flex;
    flex-wrap: wrap;
  }
}

.footer--phone{
  height: @mobileFooterHeight;
  background: @yellow;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  p{
    margin-bottom: 0;
  }

  .footer--phone-left,
  .footer--phone-right{
    width: 50%;
    font-size: 0.875rem;
    padding: 1rem;
    box-sizing: border-box;

    a{
      font-weight: 500;
      color: @white;
    }
  }

  .footer--phone-legal{
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    text-align: center;
    background: @yellow;
    font-size: 0.875rem;
    border-top: 1px solid @darkGrey;

    a{
      color: @darkGrey;

      & + a{
        margin-left: 1rem;
      }
    }
  }
}
